import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"
import Carousel from "../components/carousel"
const images = [{url:"/angela-goh-1.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Being Different</div><div className="dimensions"><span>Wood, epoxy, clay figurines</span><span>125 x 31 x 31 cm</span>
<span>Edition of 10</span></div></div>},{url:"/angela-goh-3.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Play Ball </div><div className="dimensions"><span>Clay</span><span>30 x 22 x 13 cm</span>
</div></div>}]
const images_highlight = [{type:"text", subtype:"highlight", text: "Browse the latest additions to our collection ⟶"},{url:"/michael-1.jpg", details:<div><div className="title">Michael Vaynman</div><div className="materials">Flight II</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/michael-3.jpg", details:<div><div className="title">Michael Vaynman</div><div className="materials">Metamorphosis</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m</span>
</div></div>
}]
const titles = {"h2":"Featured Artist","h3":"Michael Vaynman"}
const images_double = [{type:"image",url:"/adam-gottlieb-2.jpg", details:<div><div className="title">Adam Gottlieb</div><div className="materials">Corium</div><div className="dimensions"><span>Water colour, pigment, hide glue</span><span>125cm x 55m</span>
</div></div>
},{url:"/angela-goh-1.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Being Different</div><div className="dimensions"><span>Wood, epoxy, clay figurines</span><span>125 x 31 x 31 cm</span>
<span>Edition of 10</span></div></div>},{type:"image", url:"/adam-gottlieb-1.jpg", details:<div><div className="title">Adam Gottlieb</div><div className="materials">Liminal</div><div className="dimensions"><span>Charcoal on Paper</span><span>125cm x 55m</span>
</div></div>
}]


const IndexPage = () => (
  <Layout invert={true}>
    <SEO title="Home" />
    <div style={{ }}>
      <Carousel />
      <br />
      <Titles titles={{"h2":"New works now available"}} />
      <ImageRow images={images_highlight} />
      <ImageRow images={images_double} />


        <Titles titles={{"h2":"Featured Artist","h3":"Angeline Goh ⟶"}} />
        <ImageRow images={images} />
  
          <Titles titles={{"h2":"News", "h3":"Read more updates ⟶"}} />
            <ImageRow images={[{type:"text", text: <div>04.02.2021  ⟶ We are delighted to announce that we will be representing French sculptor Francois Jaggi. First working with drawings, he then creates the originals and then casts them in bronze. After the casting he finely sands and polishes them using different patinas. Steel and Stainless steel are other mediums he uses, as well as wire, which he welds together returning all sculptures to their ideal form giving them light and graceful movement. </div>, span:"9"}] } />
          

    </div>
  </Layout>
)

export default IndexPage
